@import '../../../../scss/theme-bootstrap';

.gnav-util {
  &__icon--camera {
    background: url('/sites/drjart/themes/drjart_base/img/camera.svg') no-repeat scroll 0 0 rgba(0, 0, 0, 0);
    background-size: contain;
    border: none;
    cursor: pointer;
    height: 22px;
    width: 22px;
  }
}
